// Shadow on card 
.has-shadow {
    &.bg-lighter {
        .card, .accordion {
            box-shadow: 0 0 8px -3px rgba($shadow-color-light, 0.15);
        }
    }
    .card, .accordion {
        box-shadow: 0 0 6px -2px rgba($shadow-color-light, 0.2);
    }
}

.is-scrollable {
    overflow: auto;
}


// country region
.country{
    &-list{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 100%;
        }
    }
    &-item{
        display: flex;
        align-items: center;;
    }
    &-flag{
        width: 1.25rem;
        margin-right: .75rem;
    }
    &-name{
        font-size: 1rem;
        color: $base-text;
    }
}

@include media-breakpoint-up(sm){
    .country{
        &-list{
            li{
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-up(lg){
    .country{
        &-list{
            li{
                width: 33.33%;
            }
        }
    }
}
//
.switch-text{
    &-normal{
        .collapsed &{
            display: none;
        }
    }
    &-collapsed{
        display: none;
        .collapsed &{
            display: inline-flex;
        }
    }
}
//
.close {
    float: right;
    font-size: $close-font-size;
    font-weight: $close-font-weight;
    line-height: 1;
    color: $close-color;
    text-shadow: $close-text-shadow;
    opacity: .5;
    &:hover{
        color: $close-color;
        text-decoration: none;
    }
    &:not(:disabled):not(.disabled) {
        &:hover-focus{
            opacity: .75;
        }
    }
  }

//alert close
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
// popover
.popover-header{
    border-bottom: $popover-border-width solid darken($popover-header-bg, 5%);
}