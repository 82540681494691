$list-default-icon: $ni-bullet-fill !default;
$list-checked-icon: $ni-check-fill-c !default;
$list-checked-circle-icon: $ni-check-circle-fill !default;
$list-cross-icon: $ni-cross-fill-c !default;

.list{
    &:not(:last-child) {
        margin-bottom: $para-margin;
    }
    li{
        position: relative;
        padding-left:1.5rem;
        line-height: 1.5rem;
        &:not(:last-child){
            padding-bottom: .5rem;
        }
        &:before{
            position: absolute;
            left: 0;
            font-size: $fx-sz-14;
            line-height: 1.5rem;
            font-family: $nk-dashlite-font;
            content: $list-default-icon;
        }
        span{
            color: $base-light;
        }
        ul {
            margin-top: .5rem;
        }
        del{
            color: $base-light;
        }
    }
    &-checked{
        li:before{
            color: $accent-color;
            content: $list-checked-icon;
        }
        &.outlined{
            li:before{
                content: $ni-check-round;
            }
        }
        &-circle{
            li:before{
                color: $accent-color;
                content: $list-checked-circle-icon;
            }
            &.outlined{
                li:before{
                    content: $ni-check-circle;
                }
            }
        }
    }
    &-cross {
        li:before{
            color: rgba($danger, .8);
            content: $list-cross-icon;
        }
    }
    &-check{
        li:before{
            content: $ni-check-thick;
        }
    }
    &-lg {
        li {
            padding-left: 2rem;
            &:not(:last-child){
                padding-bottom: .75rem;
            }
            &:before{
                font-size: 1.35rem;
            }
        }
    }
    &-sm{
        li{
            padding-left: 1.2rem;
            &:not(:last-child){
                padding-bottom: .35rem;
            }
            &:before{
                font-size: $fx-sz-12;
            }
        }
    }
    &-nostyle{
        font-size: 0.875rem;
        li{
            padding-left: 0;
            &:before{
                display: none;
            }
        }
    }
}

@each $name, $value in $theme-colors {
    .list-#{$name} {
        li:before{
            color: $value;
        }
    }
}