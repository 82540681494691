.select2-dropdown {
  background-color: $select2-bg-color;
  border-radius: $select2-border-radius;
  box-shadow: $select2-box-shadow;
  border: $select2-border-width solid $select2-border-color;
  font-family: $select2-font-family;
  font-size: $select2-font-size;
  font-weight: $select2-font-weight;
  display: block;
  position: absolute;
  left: -100000px;
  z-index: 1051;
  padding: 6px;
  &--below{
    margin-top: -1px;
    border-radius: 0 0 $select2-border-radius $select2-border-radius;
  }
  &--above{
    margin-top: 1px;
    border-radius: $select2-border-radius $select2-border-radius 0 0;
  }
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: $select2-border-radius $select2-border-radius;
}

.select2-results__option {
  
  padding: .375rem ($select2-padding-x - .25rem);
  border-radius: $select2-border-radius;
  user-select: none;
  -webkit-user-select: none;
  &:not(:last-child){
    margin-bottom: 0.125rem;
  }
  &[aria-selected] {
    cursor: pointer;
  }
  .select2-lg &{
    padding: .5rem ($select2-padding-x-lg - .25rem);
  }
}

.select2-container--open .select2-dropdown {
  left: 0;
  border-color: $select2-open-border-color;
}

.select2-search--dropdown {
  display: block;
  padding: 0;
  .select2-search__field {
    padding: .375rem ($select2-padding-x - .25rem);
    width: 100%;
    border-radius: $select2-border-radius;
    margin-bottom: 6px;
    color: $select2-color;
    &:focus{
      outline: none;
      border-color: $select2-focus-border-color;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    .select2-lg &{
      padding: .5rem ($select2-padding-x-lg - .25rem);
    }
  }

  &.select2-search--hide {
    display: none;
  }
}
