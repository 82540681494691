.service{
    position: relative;
    &-icon{
        flex-shrink: 0;
        margin-bottom: 1.875rem;
        .is-compact &{
            margin-bottom: 1.25rem;
        }
        .text-center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &-inline{
        display: flex;
        .card-inner{
            display: flex;
        }
        .service-icon{
            margin-right: 1.75rem;
            margin-bottom: 0;
        }
        &.is-compact .service-icon{
            margin-right: 1.25rem;
        }
    }
    &-text{
        .title:not(:last-child){
            margin-bottom: 0.8125rem;
        }
    }
    &-s3{
        display: flex;
        .service{
            &-icon{
                margin-bottom: 0;
                margin-right: 1.5rem;
            }
        }
    }
    &-s4{
        overflow: hidden;
        transition: .3s ease;
        &:after{
            position: absolute;
            content:'';
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background-color:$primary;
            transition: .3s ease;
            opacity: 0;
        }
        &.service-inline{
            &:after{
                left: auto;
                top: 0;
                height: auto;
                width: 3px;
            }        
        }
        &:hover{
            box-shadow: 0px 6px 35.2px 4.8px rgba($shadow-color, .06);
            &:after{
                opacity: 1;
            }
        }
    }

}
@include media-breakpoint-up(xl){
    .service{
        &-s2{
            padding: 1.25rem 2rem 0;
        }
    }
}

//reviews
.review{
    &-s2{
        display: flex;
        flex-direction: column;
    }
    &-s3{
        padding-left: 1.5rem;
        position: relative;
        &:before{
            position: absolute;
            content: '';
            background-color: $primary;
            width: 3px;
            left: 0;
            top:0;
            bottom: 0;
        }
    }
    &-rating{
        margin-bottom: 0.375rem;
    }
    &-text{
        p{
            color: $base-400;
        }
    }
    &-brand{
        padding-top: 2rem;
    }
    &-name{
        font-size: 0.875rem;
        line-height: 1.625rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: $fw-medium;
        color: $darker;
    }
    &-portrait{
        flex-shrink: 0;
        &-s1{
            width: 120px;
            margin-bottom: 1.5rem;
        }
    }
    &-user + .review-text {
        margin-top: 1.375rem; 
    }
}

@include media-breakpoint-up(sm){
    .review{
        &-s2{
            flex-direction: row;
        }
        &-portrait{
            &-s1{
                margin-bottom: 0;
                margin-right: 1.5rem;
            }
        }
    }
}

@include media-breakpoint-up(md){
    .review{
        &-portrait{
            &-s1{
                margin-right: 2.25rem;
            }
        }
    }
}

// product
.product{
    &-img{
        img{
            border-radius: $border-radius;
        }
    }
    &-s1{
        transition: .3s ease;
        .product-img{
            border-radius: $border-radius;
            background: $dark-dim;
        }
        &:hover{
            transform: translateY(-.75rem);
        }
    }
    &-s2{
        overflow: hidden;
        .product-img{
            overflow: hidden;
            padding-bottom: 0;
            img{
                transition: .3s ease;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        &:hover{
            .product-img img{
                transform: scale(0.98) translateY(7px);
            }
        }
    }
    &-info{
        .product-s1 &{
            display: flex;
            justify-content: space-between;
            padding-top: 1.75rem;
        }
        .title{
            transition: .3s ease;
            .product:hover &{
                color: $accent-color;
            }
        }
        .sub-title{
            color: $base-text;
        }
    }
    &-price{
        font-size: 1.25rem;
        span{
            font-weight: $fw-bold;
            color: $danger;
        }
        del{
            font-size: 1rem;
            color: $base-text;  
        }
    }
}

//user
.user{
    &-s1 {
        display: flex;
        align-items: center; 
        .img {
            margin-right: 1rem; 
        }
        .name {
            margin-bottom: 0.375rem; 
        }
        .role {
            font-size: 0.875rem; 
        }
        .rating{
            padding-top: .25rem;
        }
    }
}

//brand
.brand{
    &-item{
        img{
            height: 100%;
        }
    }
}

//play
.play{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    &-text{
        padding-top: .75rem;
        color: $base-text;
    }
}