/// Toggles
///////////////////////////
.toggle{
    &-content{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: calc(100% + 10px);
        z-index: 9;
        background: $white;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        transform: translateY(10px);
        &-right{
            left: auto;
            right: 0;
        }
        &-bottom{
            top: auto;
            bottom: 0;
        }
        &.content-active{
            transition: all .2s ease;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
    &-slide{
        position: fixed;
        top: 0;
        z-index: 999;
        min-width: 260px;
        max-width: calc(100% - 40px);
        transition: transform 650ms ease;
        &-left{
            left: 0;
            transform: translateX(-100%);
        }
        &-right{
            right: 0;
            transform: translateX(100%);
        }
        &.content-active{
            transform: translate(0) !important;
        }
    }
    &-overlay{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($dark,.2);
        z-index: 900;
        animation: overlay-fade-in .4s ease 1;
    }
}

.toggle-expand{
    &-content{
        display: none;
        &.expanded{
            display: block;
        } 
    }
}

.inactive-text{
    .toggle-expand &, .toggle-opt & {
        display: block;
    }
    .toggle-expand.active > &, .toggle-opt.active > &{
        display: none;
    }
}
.active-text{
    .toggle-expand &, .toggle-opt & {
        display: none;
    }
    .toggle-expand.active > &, .toggle-opt.active > &{
        display: block;
    }
}

body.toggle-shown{
    overflow: hidden;
}

@each $breakpoint in map-keys($container-max-widths) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $container-max-widths);
        .toggle-break#{$infix}{
            transition: none;
            opacity: 1;
            visibility: visible;
            transform: translateY(0) !important;
            position: static;
            background:transparent;
            box-shadow: none;
            z-index: 1 !important;
            &.collapse{
                display: block;
            }
        }
    }
}