@use "sass:math";

$form-control-select-icon: $ni-chevron-down !default;
.form{
    &-icon{
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        width: add($field-padding-x, + 24px);
        height: add($field-padding-x, + 24px);
        .icon{
            font-size: 16px;
            color: $base-light;
        }
        + .form-control{
            padding-left: add($field-padding-x, + 24px);
        }
        &-right{
            left: auto;
            right: - $field-border-width;
            + .form-control{
                padding-left: $input-padding-x;
                padding-right:  add($field-padding-x, + 24px);
            }
        }
    }
    &-info{
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $border-color;
        right: 0;
        top: 50%;
        padding: .125rem 1.25rem;
        transform: translateY(-50%);
        color: $base-light;
        + .form-control{
            padding-right: add($field-padding-x, + 24px);
        }
    }
    &-label{
        font-size: 0.875rem;
        font-weight: 500;
        color: $light-700;
        margin-bottom: .5rem;
        &-group{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: .5rem;
            .form-label {
                margin-bottom: 0;
            }
        }
        + .form-note{
            margin-top: -0.35rem;
        }
        &-outlined{
            position: absolute;
            top: $input-padding-y;
            left: math.div($input-padding-x , 1.5);
            transform-origin: left;
            padding: 0 math.div($input-padding-x , 2.5);
            font-weight: $input-font-weight;
            line-height: $input-line-height;
            color: $input-color;
            background-color: $white;
            z-index: 1;
            transition: all .3s ease;
            margin-bottom: 0;
            @include font-size($input-font-size);
            .form-control-lg ~ &{
                top: $input-padding-y-lg;
                padding: 0 math.div($input-padding-x,2.65);
                @include font-size($input-font-size-lg);
            }
            .form-control-sm ~ &{
                @include font-size($input-font-size-sm);
                top: $input-padding-y-sm;
                left: math.div($input-padding-x , 2.5);
            }
            .focused .form-control-sm ~ &,
            .form-select.form-control-sm ~ &{
                font-size: 11px;
            }
            .focused &,.form-select ~ &{
                top: add(-($input-padding-y), -2px);
                font-size: 11px;
            }
            .form-control-xl ~ &{
                @include font-size($input-font-size-xl);
                line-height: $input-line-height-xl;
                top: $input-padding-y-xl;
                padding: 0 math.div($input-padding-x-xl , 2.5);
                left: math.div($input-padding-x-xl , 1.5);
            }
            .focused .form-control-xl ~ &,
            .form-select.form-control-xl ~ &{
                top: add(-($input-padding-y-xl), -5px);
            }
            .focused .form-control-lg ~ &,.focused .form-control-xl ~ &,
            .form-select.form-control-lg ~ &,.form-select.form-control-xl ~ &{
                font-size: 12px;
            }
        }
    }
    &-note{
        font-size: $fx-sz-12;
        color: $base-light;
        font-style: italic;
        display: block;
        &-alt{
            font-size: $fx-sz-12;
            color: $base-light;
        }
        &-group{
            display: flex;
            justify-content: space-between;
            margin-top: 0.5rem;
            margin-bottom: -0.25rem;
        }
    }
    &-control{
        &-xl {
            height: $input-height-xl;
            padding: $input-padding-y-xl $input-padding-x-xl;
            @include font-size($input-font-size-xl);
            line-height: $input-line-height-xl;
            @include border-radius($input-border-radius-xl);
        }
        &-outlined{
            cursor: pointer;
            .focused &{
                cursor: auto;
            }
        }
        &-wrap,&-group{
            position: relative;
            + .form-note{
                margin-top: 0.5rem;
            }
        }
        &-noborder{
            border-color: transparent;
            &:focus{
                border-color: $input-border-color;
                box-shadow: none;
            }
        }
        &-solid{
            border-color: $input-bg;
            &:focus{
                box-shadow: none;
                border-color: $input-border-color;
            }
        }
        &-number{
            font-size: 20px;
            padding: 20px;
            height: 74px;
        }
        &-password{
            &-big{
                font-size: 44px;
                height: calc(#{$field-padding-x}*2 + #{$field-line-height} + #{$field-border-width});
                padding: $field-padding-x 1rem;
            }
        }
        &.error{
            // color: $input-error-color;
            border-color: $input-error-border-color;
            &:focus{
                box-shadow: $input-error-focus-box-shadow;
            }
        }
        &.focus{
            border-color: $input-focus-border-color;
            box-shadow: $input-focus-box-shadow;
        }
        &-simple{
            border: none;
            padding: 0;
            &:focus{
                box-shadow: none;
            }
        }
    }
    &-group{
        position: relative;
        margin-bottom: 1.25rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &-select{
        height: $input-height;
        &:not(.form-control){
            opacity: 0;
        }
        &-sm,&[data-ui*="sm"]{
            height: $input-height-sm;
        }
        &-lg,&[data-ui*="lg"]{
            height: $input-height-lg;
        }
        &-xl,&[data-ui*="xl"]{
            height: $input-height-xl;
        }
    }
    &-focus{
        &-none{
            &:focus{
                border-color: transparent;
                box-shadow: none;
            }
        }
    }
    &-action {
        .btn + .btn {
            margin-left: 1.25rem;
        }
    }
    &-round{
        border-radius: 100px;
    }
}
.custom-select{
    appearance: none;
    -webkit-appearance: none;
    option{
        border-radius: $border-radius-sm;
        padding: 5px .5rem;
        margin: 2px -.5rem;
        cursor: pointer;
        color: $base-text;
        &:last-child{
            margin-bottom: -0.5rem;
        }
    }
}
.form-control-select{
    position: relative;
    .form-control{
        appearance: none;
        -webkit-appearance: none;
    }
    .form-select{
        background-image: none;
    }
    &-multiple{
        .custom-select{
            padding-top: 0.375rem;
            padding-bottom: 0.875rem;
            option{
                border-radius: $border-radius-sm;
                padding: 5px .5rem;
                margin: 2px -.5rem;
                cursor: pointer;
                color: $base-text;
                &:last-child{
                    margin-bottom: -0.5rem;
                }
            }
        }
    }
    &:after{
        font-family: $nk-dashlite-font;
        content:$form-control-select-icon;
        pointer-events: none;
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        right: 1px;
        top: 1px;
        width: calc(#{$input-height} - 2px);
        height: calc(#{$input-height} - 2px);
        &.sm:after{
            width: calc(#{$input-height-sm} - 2px);
            height: calc(#{$input-height-sm} - 2px);
        }
        &.lg:after{
            width: calc(#{$input-height-lg} - 2px);
            height: calc(#{$input-height-lg} - 2px);
        }
    }
}

textarea{
    &.no-resize{
        resize: none;
    }
    &.form-control{
        min-height: $textarea-min-height;
        padding-top: $input-padding-y*1.5;
    }
    &.textarea-sm{
        min-height: $textarea-min-height-sm;
    }
    &.textarea-lg{
        min-height: $textarea-min-height-lg;
    }
    &.textarea-auto{
        min-height: 0;
    }
}

// validation
.custom-file-input,.form-control{
    ~ .error, ~ .invalid{
        color: $field-error-color;
        font-size: $fx-sz-11;
        font-style: italic;
    }
}


.custom-btn{
    &-check{
        position: relative;
        input{
            position: absolute;
            opacity: 0;
            height: 1px;
            width: 1px;
            &:checked ~{
                label{
                    color: $white;
                    border-color: $accent-color;
                    background-color: $accent-color;
                }
            }
        }
        label{
            display: inline-block;
            border:2px solid $light;
            background-color: $light;
            font-weight: $fw-medium;
            color: $base-text;
            text-align: center;
            padding: 0.4375rem 1.125rem;
            font-size: 0.8125rem;
            line-height: 1.25rem;
            border-radius: $border-radius;
            transition: all .3s;
            margin-bottom: 0 !important;
        }
    }
    &-sm{
        label{
            padding: 0.25rem 1rem;
            font-size: 0.75rem;
            line-height: 1.25rem;
        }
    }
    &-round{
        label{
            border-radius: 60px;
        }
    }
    &-outline{
        label{
            background-color: transparent;
        }
    }
}

$upload-invalid: $ni-alert-fill !default;
$upload-valid: $ni-check-circle-fill !default;
$upload-error: $ni-cross-circle-fill !default;
//Passcode Toggle
.passcode{
    &-icon {
        display: none;
        &.icon-show {
            display: block;
            .is-shown &{
                display: none;
            }
            .is-hidden &{
                display: block;
            }
        }
        &.icon-hide {
            .is-shown &{
                display: block;
            }
            .is-hidden &{
                display: none;
            }
        }
    }
}

// Dropdown
.form-dropdown{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $base-light;
    > div > span{
        margin:0 0.5rem;
    }
    .dropdown{
        > a{
            display: inline-flex;
        }
    }
}

// clipboard
.form-text-hint{
    position: absolute;
    right: $input-height-border;
    top: $input-height-border;
    height: calc(#{$input-height-inner} - #{$input-height-border});
    display: flex;
    align-items: center;
    color: $accent-color;
    padding-left: 1rem;
    padding-right: .75rem;
    background: $white;
    border-radius: $input-border-radius;
    .icon + span,span + .icon{
        margin-left: 0.25rem;
    }
}

//Upload
.nk-upload{
    &-input{
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(2.125rem + 2px);
        margin: 0;
        opacity: 0;
    }
    &-label{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem;
        width: 100%;
        min-height: 150px;
        border-radius: $border-radius;
        border: 1px dashed $border-light;
        padding-bottom: 0;
    }
    &-init{
        display: block;
        text-align: center;
    }
    &-files{
        padding-top: 2rem;
        display: flex;
        justify-content: center;
        margin: -0.5rem;
        padding-bottom: 2rem;
        li{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 0.5rem;
            img{
                width: 60px;
                flex-shrink: 0;
                border-radius: $border-radius;
            }
        }
        .is-multiple &{
            li{
                img{
                    width: 40px;
                }
                .nk-upload-name{
                    display: none;
                }
            }
        }
    }
    &-name{
        margin: .5rem 0 0;
        color: $base-text;
        font-size:.8125rem;
        font-weight: $fw-medium;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-response{
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        .message{
            font-size: $fx-sz-12;
            color:$base-light;
            + .icon{
                margin-left: 0.5rem;
            }
        }
        .icon{
            &:after{
                font-family: $nk-dashlite-font;
            }
            + .message{
                margin-left: 0.5rem;
            }
        }
        &.invalid{
            .icon{
                color: $warning;
                &:after{
                    content: $upload-invalid;
                }
            }
        }
        &.valid{
            .icon{
                color: $success;
                &:after{
                    content: $upload-valid;
                }
            }
        }
        &.error{
            .icon{
                color: $danger;
                &:after{
                    content: $upload-error;
                }
            }
        }
    }
    &-foot{
        display: flex;
        justify-content: space-between;
        border-top: 1px dashed $border-light;
        padding: 1rem 1.25rem;
        margin: 0 -1.5rem;
        > span, > button{
            margin: .25rem;
        }
    }
}

.form {
    &-editor-custom {
        textarea {
            border-radius: $border-radius $border-radius  0 0;
        }
    }
    &-editor-action {
        border: 1px solid $border-color;
        border-top: 0;
        border-radius: 0 0 $border-radius $border-radius;
        padding: .25rem .5rem;
        > .link{
            padding: .5rem;
            &.collapsed {
                color: $base-light;
            }
            .icon {
                margin-right: .25rem;
            }
        }
    }
    &-editor-btn-group, &-btn-group{
        display: flex;
        align-items: center;
        margin: -0.25rem;
        flex-wrap: wrap;
        li {
            padding: .25rem;
            line-height: 0.5rem;
        }
    }
    &-btn-group {
        li:first-child {
            margin-right: .5rem;
        }
    }
    &-btn-secondary {
        margin-left: auto;
    }
}


// number
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

.number{
    &-spinner{
        padding-left: $input-padding-x + $btn-line-height + $btn-padding-y*2;
        padding-right: $input-padding-x + $btn-line-height + $btn-padding-y*2;
        text-align: center;
        &:focus{
            border-color: $input-border-color;
        }
        &-btn{
            position: absolute;
            top: 0;
        }
    }
    &-plus{
        right: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &-minus{
        left: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

// for date range
.input-group-addon{
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    background-color: $lighter;
    border: $input-border-width solid $input-border-color;
    font-size: 0.75rem;
    border-radius: $border-radius;
    &:not(:last-child){
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &:not(:first-child){
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
