.widget{
    &-title{
        margin-bottom: 1.5rem;
    }
    &-about{
        .logo-link + p{
            margin-top: 1rem;
        }
    }
    &-contact{
        margin: -.5rem 0;
        li{
            padding: .5rem 0;
            display: flex;
            align-items: flex-start;
            font-size: .9375rem;
            line-height: 1.5rem;
            .icon{
                width: 2rem;
                flex-shrink: 0;
                line-height: 1.5rem;
                color: $accent-color;
            }
        }
    }
}