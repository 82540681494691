.select2-selection--multiple {
  background-color:$select2-bg-color;
  border: 1px solid $select2-border-color;
  border-radius: $select2-border-radius;
  font-family: $select2-font-family;
  font-size: $select2-font-size;
  font-weight: $select2-font-weight;
  cursor: text;
  .select2-search--inline{
    input::placeholder{
      color: $select2-placeholder-color;
    }
  } 
  .select2-search--inline{
    .select2-search__field{
      padding-left: ($select2-padding-x - 0.1875rem) !important;
    }
  }
  .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding:.125rem 0.1875rem;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    li {
      list-style: none;
    }
  }

  .select2-selection__clear {
    cursor: pointer;
    font-weight: bold;
    margin-top: 5px;
    float: right;
    margin-right: 10px;
    // This padding is to account for the bottom border for the first
    // selection row and the top border of the second selection row.
    // Without it, selections on the first row may be offset incorrectly
    // and appear in their own row instead of going to the second row
    padding: 1px;
  }

  .select2-selection__choice {
    background-color: $select2-dropdown-item-active;
    border-radius: $border-radius-sm;
    cursor: default;
    line-height: 1.5rem;
    margin:0.1875rem 0.1875rem;
    padding: 0 .5rem;
  }

  .select2-selection__choice__remove {
    color: $select2-color;
    cursor: pointer;

    display: inline-block;
    font-weight: bold;
    margin-right: .5rem;
    &:hover {
      color: $select2-color;
    }
  }
}
&.select2-lg .select2-selection--multiple{
  font-size: $select2-font-size-lg;
  .select2-selection__choice {
    border-radius: $border-radius;
    padding: 0.25rem .75rem;
  }
}
&.select2-container--below.select2-container--open{
  .select2-selection--multiple{
    border-radius: $select2-border-radius $select2-border-radius 0 0;
  }
}
&.select2-container--above.select2-container--open{
  .select2-selection--multiple{
    border-radius: 0 0 $select2-border-radius $select2-border-radius;
  }
}

&.select2-container--focus {
  .select2-selection--multiple {
    border: 1px solid $select2-focus-border-color;
    outline: 0;
  }
}

&.select2-container--disabled {
  .select2-selection--multiple {
    border-color: $select2-border-color;
    background-color: rgba($select2-border-color, 0.3);
    cursor: default;
  }
  .select2-selection__choice__remove {
    display: none;
  }
}
&.select2-container--open {
  .select2-selection--multiple {
    border-color: $select2-open-border-color;
  }
}
